import React, { useEffect, useState } from "react";
import { DashboardLayout, DeletePopupModal, PopupModal } from "../Components";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import { MdOutlineEdit } from "react-icons/md"
import { FormParser } from "../Components";
import Masters from "../Components/masters.json";
import { FileManager } from "../Components/FileManager";
import { Modal } from 'react-bootstrap';
import axiosConfig from "../Service/axiosConfig";
import { AiOutlinePlus } from "react-icons/ai"

const Sitesetting = () => {
    const MastersModal = Masters.SiteSettings;
    const [siteSettings, setSiteSettings] = useState([])
    const [readOnly, setReadOnly] = useState(true);
    const [message, setMessage] = useState(null)
    const [show, setShow] = useState(false);
    const [Error, SetError] = useState("");
    const [popupType, setpopupType] = useState("");
    const handleShow = () => {
        setShow(true)
    }
    const GetSiteSettings = async () => {
        try {
            const responce = await axiosConfig.get(`/sitedata/site/`)
            console.log(responce.data.results[0], "site settings")
            if (responce.data.results.length > 0) {
                let site_settings = {
                    ...responce.data.results[0],
                    "template": responce.data.results[0].template?.id,
                    "business_category": responce.data.results[0].business_category?.id
                };
                setSiteSettings(site_settings)
            }
        }
        catch (error) {
            console.log(error)
        }

    }

    const formSubmit = async (Data) => {
        try {
            const updatedData = { ...Data };
            updatedData.logo = Data.logo.id;
            updatedData.favicon = Data.favicon.id;
            updatedData.banner = Data.banner.id;
            const formData = new FormData();
            Object.entries(updatedData).forEach(([key, value]) => {
                if (key === "default_primary" || key === "default_secondary") {
                    formData.append(key, value);
                }
                else if (value || value === '') {
                    formData.append(key, value);
                }
            });
            axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
            if (Data.id) {
                const response = await axiosConfig.patch(`/sitedata/site/${Data.id}/`, formData)
                console.log("updated site settings successfully : ", response)
                setMessage("Site settings updated successfully")
            } else {
                const response = await axiosConfig.post(`/sitedata/site/`, formData)
                console.log("created site settings successfully : ", response)
                setMessage("Site settings created successfully")
            }
            setpopupType("success")
            setReadOnly(true)
        } catch (error) {
            console.log("Error setting the site data:", error.response.data);
            setpopupType("error");
            const errorValues = Object.values(error.response.data.error);
            const errorKeys = Object.keys(error.response.data.error);
            if (errorValues.length > 0 && errorValues[0].length > 0) {
                SetError(`${errorValues[0][0]} at ${errorKeys[0]}`);
            }

        }
    }
    const handleFileChange = (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        console.log(e.target, name, "banner check")
        if (file) {
            setSiteSettings((prev) => ({
                ...prev,
                [name]: file
            }))
        }
        let ddd = siteSettings;
        console.log({ ...ddd, [name]: file }, "inside handlefilechange......")
    };

    useEffect(() => {
        GetSiteSettings();
    }, [])

    return (
        <>
            <div className="child-sidenav">
                <SettingNavbarComponent />
                <div className="childnav-content mt-2">
                    <FormParser
                        modelObject={MastersModal}
                        buttonTitle="Save"
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}
                        formData={siteSettings}
                        formSubmit={formSubmit}
                        error={Error}
                    />
                    {popupType != "" &&
                        <PopupModal popupType={popupType} setpopupType={setpopupType} Navigation="/dashboard/masters/sitesettings" Message={message} error={Error} />
                    }
                    {readOnly &&
                        <button className={`btn btn-primary d-flex align-items-center`} onClick={() => setReadOnly(false)}>
                            <div>Edit</div>
                            <MdOutlineEdit className="ms-5" />
                        </button>
                    }
                </div>
            </div>
        </>
    )
}
export { Sitesetting }