import React, { useEffect, useState } from "react";
import { DashboardLayout, FormParser, PopupModal } from "../Components";
import Masters from "../Components/masters.json";
import axiosConfig from "../Service/axiosConfig";
import DataTable from 'react-data-table-component';
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { MdDeleteOutline, MdOutlineEdit } from "react-icons/md"
import { NavLink } from "react-router-dom";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import { Modal } from 'react-bootstrap';
import { BiSearch } from "react-icons/bi"

const AdminProfile = () => {
    const [Loading, setLoading] = useState(false)
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(20);
    const [stores, setStores] = useState([])
    const [search, SetSearch] = useState({
        search: ""
    })
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState({})
    const [modalTitle, setModalTitle] = useState("Add New Store");
    const [popupType, setpopupType] = useState("")
    const [Error, SetError] = useState("")
    const [message, setMessage] = useState('')
    const ecommerce_type = JSON.parse(localStorage.getItem("site_data")).ecommerce_type
    const store_columns = [
        {
            name: 'ID',
            selector: 'id',
            sortable: true,
            width: '50px'
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            width: '150px'
        },
        {
            name: 'Logo',
            cell: row => <img src={row.logo_image ? row.logo_image.image : ''} alt={row.name} style={{ width: '50px' }} />,
            width: '100px'
        },
        {
            name: 'Banner',
            cell: row => <img src={row.banner_image ? row.banner_image.image : ''} alt={row.name} style={{ width: '100px' }} />,
            width: '100px'
        },
        {
            name: 'Address',
            selector: 'address',
            sortable: true,
            width: '400px'
        },
        {
            name: 'Actions',
            cell: row => (
                // <NavLink to={`/dashboard/${row.id}/store_management`}>
                //     <MdOutlineEdit />
                // </NavLink>
                <MdOutlineEdit onClick={() => handleEdit(row.id)} />
            ),
            width: '100px'
        }
    ];

    const GetStores = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/stores/stores/?page=${currentPage}&page_size=${RowPerPage}`)
            setStores(responce.data.results)
            setLoading(false)
            setTotalRows(responce.data.count)
        }
        catch (error) {
            console.log(error)
        }

    }

    const HandleSearch = (e) => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value
        console.log(name, value)
        SetSearch({ ...search, [name]: value })
        axiosConfig.get(`/stores/stores/?search=${value}`).then(res => {
            setStores(res.data.results)
        }).catch(error => {
            console.log(error)
        })
    }
    const handleSort = (column, direction) => {
        console.log(column.selector, direction)
        axiosConfig.get(`/stores/stores/?ordering=${direction === "asc" ? column.selector : -column.selector}`).then(res => {
            setStores(res.data.results)
        }).catch(error => {
            console.log(error)
        })
        setCurrentPage(1);
    };

    const handleClose = () => {
        setShow(false)
        SetError("")
        setpopupType("")
    }

    const handleEdit = async (id) => {
        setModalTitle("Edit Store")
        setShow(true)
        try {
            const response = await axiosConfig.get(`/stores/stores/${id}/`)
            console.log(response)
            setFormData(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const handleAdd = () => {
        setShow(true)
        setModalTitle("Add New Store")
        setFormData({})
        SetError("")
        setpopupType("")

    }

    const formSubmit = (Data) => {
        console.log(Data)
        const data_to_post = {
            ...Data,
            logo_image: Data.logo_image?.id,
            banner_image: Data.banner_image?.id,
            spike_percentage: parseFloat(Data.spike_percentage) > 0 ? parseFloat(Data.spike_percentage) : 0.0
        }
        axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
        if (!Data.express_delivery && !Data.schedule_delivery) {
            SetError("Please check The delivery option.");
        }
        // else{
        if (Data.id) {
            axiosConfig.put(`/stores/stores/${Data.id}/`, data_to_post).then(res => {
                console.log(res)
                setpopupType("success")
                setShow(false)
                setMessage("Store updated successfully")
                GetStores()
                SetError("")
            }).catch(error => {
                console.log(error)
                const ErrorData = []
                ErrorData.push({
                    code: error.response.data.code || ["null"],
                    name: error.response.data.name || ["null"],
                })
                console.log(ErrorData[0])
                if (ErrorData[0].code[0] === "priority with this code already exists.") {
                    SetError(" code already exists.")
                }
                if (ErrorData[0].name[0] === "priority with this name already exists.") {
                    SetError("Tittle already Exists")
                }
                if (error.response.data?.detail) {
                    SetError(error.response.data.detail)
                }
                setpopupType("error")
            })
        }
        else {
            axiosConfig.post(`/stores/stores/`, data_to_post).then(res => {
                console.log(res)
                setpopupType("success")
                setShow(false)
                setMessage("Store added successfully")
                GetStores()
                SetError("")
            }).catch(error => {
                console.log(error)
                SetError(error.response.data.detail)
                setpopupType("error")
            })
        }
        // }
    }

    useEffect(() => {
        GetStores();
    }, [currentPage, RowPerPage])
    return (
        <>
            <div className="child-sidenav">
                <SettingNavbarComponent />
                <div className="childnav-content">
                    <div className="d-flex align-items-center">
                        <div className="input-cat-with-icon">
                            <span className="icon-product">
                                <BiSearch />
                            </span>
                            <input
                                type="text"
                                className="input-control"
                                placeholder="Search"
                                name="search"
                                value={search.search}
                                onChange={HandleSearch}
                            />
                        </div>
                        <div className="cat-button">
                            <div className="btn" onClick={handleAdd}>
                                + Add New
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <DataTable
                            columns={store_columns}
                            data={stores}
                            pagination paginationTotalRows={totalRows}
                            paginationPerPage={RowPerPage}
                            paginationDefaultPage={currentPage}
                            onChangePage={(page) => setCurrentPage(page)}
                            paginationPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            paginationRowsPerPageOptions={[10, 20, 25, 30, 40, 50]}
                            onChangeRowsPerPage={(RowsPerPageChange) => setRowPerPage(RowsPerPageChange)}
                            onSort={handleSort}
                            paginationServer fixedHeader subHeader customStyles={tableCustomStyles}
                            progressPending={Loading}
                            progressComponent={<div className="loader-container">
                                <img src={require('../Components/FormParser/images/loader2.gif')} alt="Loading..." />
                            </div>}
                            subHeaderComponent={
                                <input type="text" className="form-control w-25" name="search" value={search.search} onChange={HandleSearch} />}
                        />
                    </div>
                </div>
                <Modal
                    size="xl"
                    show={show}
                    onHide={handleClose}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            {modalTitle}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="Loginerror">
                            <p>{Error}</p>
                        </div>
                        {ecommerce_type === "Hyperlocal" ?
                            <FormParser modelObject={modalTitle === "Edit Store" ? Masters.StoreManagerHyperlocal : Masters.AddStoreHyperlocal} formData={formData} formSubmit={formSubmit} buttonTitle='Save' />
                            :
                            <FormParser modelObject={modalTitle === "Edit Store" ? Masters.StoreManager : Masters.AddStore} formData={formData} formSubmit={formSubmit} buttonTitle='Save' />
                        }
                    </Modal.Body>
                </Modal>
                {popupType != "" &&
                    <PopupModal popupType={popupType} Navigation="/dashboard/admin/profile" Message={message} error={Error} setpopupType={setpopupType} />
                }
            </div>
        </>
    )
}
export { AdminProfile }
