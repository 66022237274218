import React, { useEffect, useState } from "react";
import { SettingNavbarComponent } from "../Components/navbar/settingsnav";
import axiosConfig from "../Service/axiosConfig";
import { MdOutlineEdit } from "react-icons/md";
import { PopupModal } from "../Components";

const ConfigureChargesHyperlocal = () => {
    const [readOnly, setReadOnly] = useState(true);
    const [waiverData, setWaiverData] = useState({});
    const [deliveryData, setDeliveryData] = useState({});
    const [packingData, setPackingData] = useState({});
    const [scheduleBelow10, setScheduleBelow10] = useState({
        from_kg: 0,
        to_kg: 10,
        charges: 0,
        delivery_type: "Schedule"
    });
    const [scheduleAbove10, setScheduleAbove10] = useState({
        from_kg: 11,
        to_kg: 50,
        charges: 0,
        delivery_type: "Schedule"
    });
    const [loading, setLoading] = useState(false);
    const [popupType, setpopupType] = useState("");
    const [error, setError] = useState('')
    const [message, setMessage] = useState("")

    const GetChargesData = async () => {
        try {
            const waiver_response = await axiosConfig.get('/masters/waier-charges/');
            const delivery_response = await axiosConfig.get('/masters/delivery-charges-configuration/');
            const packing_response = await axiosConfig.get('/masters/packing-charges-configuration/');
            if (waiver_response.data.results.length > 0) {
                setWaiverData(waiver_response.data.results[0]);
            }
            if (delivery_response.data.results.length > 0) {
                setDeliveryData(delivery_response.data.results[0]);
            }
            if (packing_response.data.results.length > 0) {
                let schedule_below_10 = packing_response.data.results.find((p) => p.delivery_type === "Schedule" && parseFloat(p.from_kg) === 0)
                let schedule_above_10 = packing_response.data.results.find((p) => p.delivery_type === "Schedule" && parseFloat(p.from_kg) === 11)
                setScheduleBelow10({ ...schedule_below_10, from_kg: 0, to_kg: 10, delivery_type: "Schedule" });
                setScheduleAbove10({ ...schedule_above_10, from_kg: 11, to_kg: 50, delivery_type: "Schedule" });
                setPackingData(packing_response.data.results);
            }

        } catch (error) {
            console.log(error)
        }
    }

    const HandleChange = (type, key, e) => {
        switch (type) {
            case "setWaiverData":
                setWaiverData({ ...waiverData, [key]: e.target.value })
                break;
            case "setDeliveryData":
                setDeliveryData({ ...deliveryData, [key]: e.target.value })
                break;
            case "setScheduleBelow10":
                setScheduleBelow10({ ...scheduleBelow10, [key]: e.target.value })
                break;
            case "setScheduleAbove10":
                setScheduleAbove10({ ...scheduleAbove10, [key]: e.target.value })
                break;
            case "freeDelivery":
                setDeliveryData({ ...deliveryData, [key]: e.target.checked })
            default:
                break;
        }
    }

    const HandleSubmit = async () => {
        setLoading(true);
        try {
            const chargesConfigurations =
                [
                    { data: waiverData, endpoint: 'waier-charges' },
                    { data: deliveryData, endpoint: 'delivery-charges-configuration' },
                    { data: scheduleBelow10, endpoint: 'packing-charges-configuration' },
                    { data: scheduleAbove10, endpoint: 'packing-charges-configuration' }
                ]

            for (const { data, endpoint } of chargesConfigurations) {
                const url = data.id ? `/masters/${endpoint}/${data.id}/` : `/masters/${endpoint}/`;
                const method = data.id ? 'put' : 'post';

                await axiosConfig[method](url, data);
            }
            setReadOnly(true);
            setpopupType("success")
            setMessage("Saved Charges successfully")
            setError("")
        } catch (error) {
            console.log("Error While Saving", error);
            if (Object.values(error.response.data).length > 0) {
                console.log(Object.values(error.response.data)[0])
                Object.values(error.response.data)[0][0] === 'A valid number is required.' ?
                    setError("All fields with '*' are mandatory") :
                    setError(Object.values(error.response.data)[0])
            }
            setpopupType("error")
        }
        setLoading(false);
    };

    useEffect(() => {
        GetChargesData()
    }, [])
    return (
        <>
            <div className="child-sidenav">
                <SettingNavbarComponent />
                <div className="childnav-content mt-2">
                    {error && <p className="text-danger">{error}</p>}
                    <>
                        <div className="form-group">
                            <h6>Packing charges</h6>
                            <div className="row">
                                <div className="col-4">
                                    <label className="form-label">Below 10kg <span className="required-star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={scheduleBelow10?.charges}
                                        onChange={(e) => HandleChange("setScheduleBelow10", "charges", e)}
                                        disabled={readOnly}
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">Above 10kg <span className="required-star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={scheduleAbove10?.charges}
                                        onChange={(e) => HandleChange("setScheduleAbove10", "charges", e)}
                                        disabled={readOnly}
                                    />
                                </div>
                                <div className="col-4">
                                    <label className="form-label">Packing Charges waiver if order value above <span className="required-star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={waiverData?.schedule_packing_waier_order_value_above}
                                        onChange={(e) => HandleChange("setWaiverData", "schedule_packing_waier_order_value_above", e)}
                                        disabled={readOnly}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-6">
                                    <label className="form-label">Schedule delivery charges <span className="required-star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={deliveryData?.schedule_delivery_charges}
                                        onChange={(e) => HandleChange("setDeliveryData", "schedule_delivery_charges", e)}
                                        disabled={readOnly}
                                    />
                                </div>
                                <div className="col-6">
                                    <label className="form-label">Schedule delivery charges waiver if order value above <span className="required-star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={waiverData?.schedule_delivery_waier_order_value_above}
                                        onChange={(e) => HandleChange("setWaiverData", "schedule_delivery_waier_order_value_above", e)}
                                        disabled={readOnly}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-6">
                                    <label className="form-label">Express delivery charges <span className="required-star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={deliveryData.express_delivery_charges}
                                        onChange={(e) => HandleChange("setDeliveryData", "express_delivery_charges", e)}
                                        disabled={readOnly}
                                    />
                                </div>
                                <div className="col-6">
                                    <label className="form-label">Express delivery charges waiver if order value above <span className="required-star">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={waiverData?.express_delivery_waier_order_value_above}
                                        onChange={(e) => HandleChange("setWaiverData", "express_delivery_waier_order_value_above", e)}
                                        disabled={readOnly}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-6 d-flex align-items-center">
                                    <label htmlFor="checkbox" className="me-3">Free Delivery</label>
                                    <input
                                        type="checkbox"
                                        checked={deliveryData?.free_delivery_charges}
                                        onChange={(e) => HandleChange("freeDelivery", "free_delivery_charges", e)}
                                        disabled={readOnly}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                    {readOnly ?
                        <button type="button" className="btn btn-primary" onClick={() => setReadOnly(false)}>Edit</button>
                        :
                        <button type="submit" className="btn btn-primary" onClick={() => HandleSubmit()}>
                            {loading ?
                                <img src={require('../Components/FormParser/images/loader.gif')} alt="Loading..." className="loader-image" />
                                :
                                "Submit"
                            }
                        </button>
                    }
                    {popupType != "" &&
                        <PopupModal popupType={popupType} setpopupType={setpopupType} error={error} Navigation="" Message={message} />
                    }
                </div>
            </div >
        </>
    )
}

export { ConfigureChargesHyperlocal }