import React,{useState} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineCategory, MdOutlineNotifications, MdOutlineMenuBook, MdOutlineGroups } from "react-icons/md"
import { CiPercent } from "react-icons/ci"
import { BsFilePost } from "react-icons/bs"
import { RiContactsFill } from "react-icons/ri";
import { MdOutlineControlPointDuplicate } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
import { Dropdown } from 'react-bootstrap';
import { MdBrowseGallery } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
const MarketingNavbarComponent = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="categorie-header">
             <NavLink to="/dashboard/masters/blogcategories" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineCategory size={24} className="childnav-icon" />
                <p className="childnav-name">Blog Categories</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/blogposts" className="categorie-subtitle" ><div className="child-title">
                <BsFilePost size={24} className="childnav-icon" />
                <p className="childnav-name">Blog Posts</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/contacts" className="categorie-subtitle" ><div className="child-title">
                <RiContactsFill size={24} className="childnav-icon" />
                <p className="childnav-name">Leads</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/jobcategories" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineControlPointDuplicate size={24} className="childnav-icon" />
                <p className="childnav-name">Job Categories</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/Jobposts" className="categorie-subtitle" ><div className="child-title">
                <BiCategory size={24} className="childnav-icon" />
                <p className="childnav-name">Job Posts</p>
            </div></NavLink>
            {/* <NavLink to="/dashboard/masters/coupons" className="categorie-subtitle" ><div className="child-title">
                <CiPercent size={24} className="childnav-icon" />
                <p className="childnav-name">Coupons</p>
            </div></NavLink> */}
            <NavLink to="/dashboard/masters/customers" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineGroups size={24} className="childnav-icon" />
                <p className="childnav-name">Customers</p>
            </div></NavLink>
            {/* <NavLink to="/dashboard/masters/promotionalnotifications" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineNotifications size={24} className="childnav-icon" />
                <p className="childnav-name">Promotional Notifications</p>
            </div></NavLink> */}
            <NavLink to="/dashboard/masters/miscellaneoupages" className="categorie-subtitle" ><div className="child-title">
                <MdOutlineMenuBook size={24} className="childnav-icon" />
                <p className="childnav-name">Miscellaneous Pages</p>
            </div></NavLink>
            <NavLink to="/dashboard/masters/news" className="categorie-subtitle" ><div className="child-title">
                <IoNewspaperOutline size={24} className="childnav-icon" />
                <p className="childnav-name">News</p>
            </div></NavLink>
            <li className="theme-doc-sidebar-item-category theme-doc-sidebar-item-category-level-1 menu__list-item" style={{listStyle:"none"}}>
            <div className="menu__list-item-collapsible">
                <a
                    href="#"
                    className={`menu__link menu__link--sublist menu__link--sublist-caret ${isOpen ? 'open' : ''}`}
                    aria-expanded={isOpen}
                    onClick={toggleDropdown}
                >
                   <span className="child-title"><MdBrowseGallery size={24}   className="childnav-icon"/>Coupons</span>
                </a>
                <ul className={`${isOpen ? 'show' : ''}`}>
                <li><NavLink className="dropdown-item childnav-name" to="/dashboard/masters/discounts" activeClassName="active">Discount</NavLink></li>
                <li><NavLink className="dropdown-item childnav-name" to="/dashboard/masters/bogo_discount" activeClassName="active">Buy One Get One</NavLink></li>
                <li><NavLink className="dropdown-item childnav-name" to="/dashboard/masters/free_item" activeClassName="active">Free Item</NavLink></li>
                <li ><NavLink className="dropdown-item childnav-name" to="/dashboard/masters/loyalty_reward" activeClassName="active">Loyalty Reward</NavLink></li>
                    <li className="child-title"><NavLink className="dropdown-item childnav-name" to="/dashboard/masters/referral_discount" activeClassName="active">Referral Discount</NavLink></li>
                    {/* <li><NavLink className="dropdown-item childnav-name" to="/dashboard/masters/first_time" activeClassName="active">First Time</NavLink></li> */}
                </ul>
            </div>
        </li>
        </div>
    )
}

export { MarketingNavbarComponent }
