import React from "react"
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/js/bootstrap.min.js';
import { StaffLogin, Dashboard, DashboardLayout, Layout, Homedesign, TextBlock, FullWidthBanners, Carousels, PrintTable } from "./Components"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from "./Utils/ProtectedRoute"
import Logout from "./Utils/Logout";
import {
  AddModality, AddNewModality, ReferenceDoaminAdd,
  ReferenceDoaminEdit, ReferenceDomainValue, ReferenceDoaminList,
  CountryAdd, CountryEdit, CountryList,
  StateAdd, StateEdit, StateList,
  CityAdd, CityEdit, CityList,
  AreaAdd, AreaEdit, AreaList,
  DepartmentAdd, DepartmentEdit, DepartmentList, SubDepartmentList, SubDepartmentEdit,
  SubDepartmentAdd, ServiceList, ServicesAdd, ServicesEdit, ModuleList, ModuleAdd, ModuleEdit,
  ModuleScreenAdd, ModuleScreenEdit, ModuleScreenList, RoleAdd, RoleEdit, RoleList, RoleModulePermisionList,
  RoleModulePermissionAdd, RoleModulePermissionEdit, ClientCreateAdd, ClientCreateEdit, ClicnicCreateList,
  ClinicsAdd, ClinicsEdit,
  ClientBilledServices, ClientBillDomestic, ClientBillInternational, ClientBillsView, ClientBillDetails, ClientAri, NotFound, Invoices, ClientFinalBillsView, UploadCases, UploadCasesList, ClientTariff, AssignTariff, ClientTarifList,
  ReferenceDoaminValueAdd, ReferenceDomainValueEdit, ClientTariffEdit, BillHeadAdd, BillHeadEdit, BillHeadList,
  PriorityAdd, PriorityEdit, PriorityList, TariffTypeAdd, TariffTypeEdit, TariffTypeList, TariffCardAdd, TariffCardList,
  TariffCardEdit, RadioLigistAdd, RadioLiigistEdit, RadioLogistLsit, SpecialitiesAdd, SpecialitiesEdit, SpecialitiesList,
  FacilitiesAdd, FacilitiesEdit, FasilitiesList, ComplaintsAdd, ComplaintsEdit, ComplaintsList, ClinicAdd, ClinicEdit, ClinicList,
  CondationAdd, CondationEdit, CondationList, ProcudreAdd, ProcudreEdit, ProcudreList, MedicationAdd, MedicationEdit, MedicationList,
  ProvisinolDignosisList, ProvisinolDigonsisAdd, ProvisinolDigonsisEdit, InvestigationAdd, InvestigationEdit, InvestigationList, ClincInfoAdd, ClinicInfoEdit, ClinicInfoList,
  ClinicAddressAdd, ClinicAddressList, ClinicAddressEdit, ClinicSpecialites, DoctorRegestrationAdd, DoctorRegestrationList, DoctorregestastionEdit, ClinicGalleryAdd, ClinicGalleryEdit, ClinicGalleryList,
  AvalibilityAdd, AvalibilityList, AvalibiltyEdit, DoctorSpecialites, DoctorAvalibilty, DoctorVacations, ClinicVacations, PataientAdd, PataientEdit, PataientList, InfoEdit, AppointmentsAdd, AppointmentsEdit, AppointmentsList,
  Emr, EMRData, EMRinput, BillItemAdd, BillItemEdit, BillItemList, Calander, Slots,
  PatientRegistration, PatientDetails, Reports, DoctorRegestrationCliniEdit, DoctorSpecialitesClinic, DoctorAvalibiltyClinic, DoctorVacationsClinics, ClinicsAdminEdit, ClinicAdminSpecialites, AvalibilityAdminAdd, ClinicGalleryAdminEdit, ClinicAdminVacations,
  PatientInvoices, PatientRecords, PatientAppointments, PatientVisitHistory, Categories,
  Orders, StoreManagement, ManageProducts, Inventory, AdminProfile, AddStore, Subcategories, Products, Banner, HomePage, Blogposts, Sitesetting, CustomerManagement, BlogCategory, MiscellaneousPages,
  ProductForm, EmailGateway, PaymentGateway, Policies, BannerOld, TaxClassification, ConsolidatedReports, ItemClientReports, ClientWiseSales, ItemWiseSales, DateWiseSales, ConfigureChargesHyperlocal, ConfigureChargesConventional, Contacts, JobCategory, Jobposts,
  ModifyOrder, DeliveryAgents, ShippingMethods, ReactUrls, LoyaltyReward, FreeItem, FirstTime, ReferralDiscount, BogosDiscount, Discounts, DisplayCategorie,
  ShopDeals, AboutUs,
  News
} from "./Pages"

import { Calendar } from "antd";
// import ChartTable from "./Components/DashboardLayout/Chart.js"
import ChartTable from "./Components/DashboardLayout/Chart";
import { Coupon } from "./Pages/Coupons";
import { Brand } from "./Pages/Brand";
import { ShoppingMethods } from "./Pages";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<StaffLogin />} />
        <Route path='/slots' element={<Slots />} />
        <Route path='/print' element={<PrintTable />} />
        <Route path="" element={<ProtectedRoute />} >
          <Route path="/logout" element={<Logout />} />
          <Route path="/dashboard" element={<DashboardLayout />} >
            {/* <Route path="/:url" element={<Layout />} />
            <Route path="/logout" element={<Logout />} /> */}
            <Route path="/dashboard/charts" element={<ChartTable />} />
            <Route path="/dashboard/emrinput" element={<EMRinput />} />
            <Route path="/dashboard/masters/domains" element={<ReferenceDoaminList />} />
            <Route path="/dashboard/masters/domains/add" element={<ReferenceDoaminAdd />} />
            <Route path="/dashboard/masters/domains/:id/edit" element={<ReferenceDoaminEdit />} />

            <Route path="/dashboard/masters/domains/value" element={<ReferenceDomainValue />} />
            <Route path="/dashboard/masters/domains/:id/value/add" element={<ReferenceDoaminValueAdd />} />
            <Route path="/dashboard/masters/domains/value/:id/edit" element={<ReferenceDomainValueEdit />} />

            <Route path="/dashboard/masters/countries" element={<CountryList />} />
            <Route path="/dashboard/masters/countries/add" element={<CountryAdd />} />
            <Route path="/dashboard/masters/countries/:id/edit" element={<CountryEdit />} />

            <Route path="/dashboard/masters/states" element={<StateList />} />
            <Route path="/dashboard/masters/states/add" element={<StateAdd />} />
            <Route path="/dashboard/masters/states/:id/edit" element={<StateEdit />} />

            <Route path="/dashboard/masters/cities" element={<CityList />} />
            <Route path="/dashboard/masters/cities/add" element={<CityAdd />} />
            <Route path="/dashboard/masters/cities/:id/edit" element={<CityEdit />} />

            <Route path="/dashboard/masters/areas" element={<AreaList />} />
            <Route path="/dashboard/masters/areas/add" element={<AreaAdd />} />
            <Route path="/dashboard/masters/areas/:id/edit" element={<AreaEdit />} />

            <Route path="/dashboard/masters/departments" element={<DepartmentList />} />
            <Route path="/dashboard/masters/departments/add" element={<DepartmentAdd />} />
            <Route path="/dashboard/masters/departments/:id/edit" element={<DepartmentEdit />} />

            <Route path="/dashboard/masters/sub-departments" element={<SubDepartmentList />} />
            <Route path="/dashboard/masters/sub-departments/add" element={<SubDepartmentAdd />} />
            <Route path="/dashboard/masters/sub-departments/:id/edit" element={<SubDepartmentEdit />} />

            <Route path="/dashboard/masters/services" element={<ServiceList />} />
            <Route path="/dashboard/masters/services/add" element={<ServicesAdd />} />
            <Route path="/dashboard/masters/services/:id/edit" element={<ServicesEdit />} />

            <Route path="/dashboard/masters/billhead" element={<BillHeadList />} />
            <Route path="/dashboard/masters/billhead/add" element={<BillHeadAdd />} />
            <Route path="/dashboard/masters/billhead/:id/edit" element={<BillHeadEdit />} />

            <Route path="/dashboard/masters/priority" element={<PriorityList />} />
            <Route path="/dashboard/masters/priority/add" element={<PriorityAdd />} />
            <Route path="/dashboard/masters/priority/:id/edit" element={<PriorityEdit />} />

            <Route path="/dashboard/qms/appointments" element={<AppointmentsList />} />
            <Route path="/dashboard/qms/appointments/add" element={<AppointmentsAdd />} />
            <Route path="/dashboard/qms/appointments/:id/edit" element={<AppointmentsEdit />} />

            <Route path="/dashboard/reports" element={<BillItemList />} />
            <Route path="/dashboard/masters/consolidated_reports" element={<ConsolidatedReports />} />
            <Route path="/dashboard/masters/item_user_reports" element={<ItemClientReports />} />
            <Route path="/dashboard/masters/item_user_reports/:date/details" element={<ItemClientReports />} />
            <Route path="/dashboard/masters/item_user_reports/:product_name/:product/product/:from/from/:to/to" element={<ItemClientReports />} />
            <Route path="/dashboard/masters/client_wise_sales" element={<ClientWiseSales />} />
            <Route path="/dashboard/masters/item_wise_sales" element={<ItemWiseSales />} />
            <Route path="/dashboard/masters/date_wise_sales" element={<DateWiseSales />} />
            <Route path="/dashboard/masters/date_wise_sales/:product_name/:product/product/:from/from/:to/to" element={<DateWiseSales />} />
            <Route path="/dashboard/worklist/billitem/add" element={<BillItemAdd />} />
            <Route path="/dashboard/worklist/:id/billitem/:id/edit" element={<BillItemEdit />} />

            <Route path="/dashboard/worklist/:id/emr/:status/:patient/:bill_item" element={<Emr />} />

            <Route path="/dashboard/appointments/calander" element={<Calander />} />

            <Route path="/dashboard/masters/tarifftype" element={<TariffTypeList />} />
            <Route path="/dashboard/masters/tarifftype/add" element={<TariffTypeAdd />} />
            <Route path="/dashboard/masters/tarifftype/:id/edit" element={<TariffTypeEdit />} />



            <Route path="/dashboard/masters/module" element={<ModuleList />} />
            <Route path="/dashboard/masters/module/add" element={<ModuleAdd />} />
            <Route path="/dashboard/masters/module/:id/edit" element={<ModuleEdit />} />

            <Route path="/dashboard/masters/tarifftype" element={<TariffTypeList />} />
            <Route path="/dashboard/masters/tarifftype/add" element={<TariffTypeAdd />} />
            <Route path="/dashboard/masters/tarifftype/:id/edit" element={<TariffTypeEdit />} />

            <Route path="/dashboard/masters/module" element={<ModuleList />} />
            <Route path="/dashboard/masters/module/add" element={<ModuleAdd />} />
            <Route path="/dashboard/masters/module/:id/edit" element={<ModuleEdit />} />

            <Route path="/dashboard/masters/modulescreen" element={<ModuleScreenList />} />
            <Route path="/dashboard/masters/modulescreen/add" element={<ModuleScreenAdd />} />
            <Route path="/dashboard/masters/modulescreen/:id/edit" element={<ModuleScreenEdit />} />

            <Route path="/dashboard/masters/role" element={<RoleList />} />
            <Route path="/dashboard/masters/role/add" element={<RoleAdd />} />
            <Route path="/dashboard/masters/role/:id/edit" element={<RoleEdit />} />

            <Route path="/dashboard/masters/rolemodulepermission" element={<RoleModulePermisionList />} />
            <Route path="/dashboard/masters/rolemodulepermission/add" element={<RoleModulePermissionAdd />} />
            <Route path="/dashboard/masters/rolemodulepermission/:id/edit" element={<RoleModulePermissionEdit />} />

            {/* <Route path="/dashboard/users/clinics" element={<ClicnicCreateList/>}/>
          <Route path="/dashboard/users/clinics/add" element={<ClinicsAdd/>}/>
          <Route path="/dashboard/users/clinics/:id/edit" element={<ClientEdit/>}/> */}
            {/* <Route path="/dashboard/users/clients/add" element={<ClientCreateAdd/>}/> */}
            {/* <Route path="/dashboard/users/clients/:id/edit" element={<ClientCreateEdit/>}/> */}

            <Route path="/dashboard/clinics/list" element={<ClicnicCreateList />} />
            <Route path="/dashboard/clinics/add" element={<ClinicsAdd />} />
            <Route path="/dashboard/clinics/:id/edit" element={<ClinicsAdminEdit />} />
            <Route path="/dashboard/clinic/" element={<ClinicsAdminEdit />} />

            <Route path="/dashboard/clinics/info" element={<ClinicInfoEdit />} />
            {/* <Route path="/dashboard/clinics/:id/info" element={<ClinicInfoEdit />} />s */}
            <Route path="/dashboard/clinics/:id/infoEdit" element={<InfoEdit />} />
            <Route path="/dashboard/billing/upload-cases" element={<UploadCases />} />
            <Route path="/dashboard/billing/upload-cases-list" element={<UploadCasesList />} />

            <Route path="/dashboard/billing/client-bills" element={<ClientBilledServices />} />
            <Route path="/dashboard/billing/domestic" element={<ClientBillDomestic />} />
            <Route path="/dashboard/billing/international" element={<ClientBillInternational />} />
            <Route path="/dashboard/billing/ari" element={<ClientAri />} />
            <Route path="/dashboard/billing/client-bills/:id/view/" element={<ClientBillsView />} />
            <Route path="/dashboard/billing/client-bill-details/:id/" element={<ClientBillDetails />} />

            <Route path="/dashboard/billing/client-final-bills" element={<Invoices />} />
            <Route path="/dashboard/billing/client-final-bills/:id/view/:month" element={<ClientFinalBillsView />} />

            <Route path="/dashboard/masters/tariffcard" element={<TariffCardList />} />
            <Route path="/dashboard/masters/tariffcard/add" element={<TariffCardAdd />} />
            <Route path="/dashboard/masters/tariffcard/:id/edit" element={<TariffCardEdit />} />

            <Route path="/dashboard/radiologist" element={<RadioLogistLsit />} />
            <Route path="/dashboard/radiologist/add" element={<RadioLigistAdd />} />
            <Route path="/dashboard/radiologist/:id/edit" element={<RadioLiigistEdit />} />

            <Route path="/dashboard/masters/specialities" element={<SpecialitiesList />} />
            <Route path="/dashboard/masters/specialities/add" element={<SpecialitiesAdd />} />
            <Route path="/dashboard/masters/specialities/:id/edit" element={<SpecialitiesEdit />} />

            <Route path="/dashboard/masters/facilities" element={<FasilitiesList />} />
            <Route path="/dashboard/masters/facilities/add" element={<FacilitiesAdd />} />
            <Route path="/dashboard/masters/facilities/:id/edit" element={<FacilitiesEdit />} />

            <Route path="/dashboard/masters/complaints" element={<ComplaintsList />} />
            <Route path="/dashboard/masters/complaints/add" element={<ComplaintsAdd />} />
            <Route path="/dashboard/masters/complaints/:id/edit" element={<ComplaintsEdit />} />

            <Route path="/dashboard/masters/condations" element={<CondationList />} />
            <Route path="/dashboard/masters/condations/add" element={<CondationAdd />} />
            <Route path="/dashboard/masters/condations/:id/edit" element={<CondationEdit />} />

            <Route path="/dashboard/masters/clinicalfindings" element={<ClinicList />} />
            <Route path="/dashboard/masters/clinicalfindings/add" element={<ClinicAdd />} />
            <Route path="/dashboard/masters/clinicalfindings/:id/edit" element={<ClinicEdit />} />

            <Route path="/dashboard/masters/procedure" element={<ProcudreList />} />
            <Route path="/dashboard/masters/procedure/add" element={<ProcudreAdd />} />
            <Route path="/dashboard/masters/procedure/:id/edit" element={<ProcudreEdit />} />

            <Route path="/dashboard/masters/medication" element={<MedicationList />} />
            <Route path="/dashboard/masters/medication/add" element={<MedicationAdd />} />
            <Route path="/dashboard/masters/medication/:id/edit" element={<MedicationEdit />} />

            <Route path="/dashboard/masters/provisinol-dignosis" element={<ProvisinolDignosisList />} />
            <Route path="/dashboard/masters/provisinol-dignosis/add" element={<ProvisinolDigonsisAdd />} />
            <Route path="/dashboard/masters/provisinol-dignosis/:id/edit" element={<ProvisinolDigonsisEdit />} />

            <Route path="/dashboard/masters/investigations" element={<InvestigationList />} />
            <Route path="/dashboard/masters/investigations/add" element={<InvestigationAdd />} />
            <Route path="/dashboard/masters/investigations/:id/edit" element={<InvestigationEdit />} />

            <Route path="/dashboard/clinics/:id/specialities" element={<ClinicSpecialites />} />
            <Route path="/dashboard/clinic/specialities" element={<ClinicSpecialites />} />
            <Route path="/dashboard/profile/specialities" element={<DoctorSpecialitesClinic />} />
            <Route path="/dashboard/profile/:id/doctor_specialities" element={<DoctorSpecialitesClinic />} />

            <Route path="/dashboard/users/:id/doctor_availability" element={<DoctorAvalibilty />} />
            <Route path="/dashboard/profile/doctor_availability" element={<DoctorAvalibilty />} />

            {/* <Route path="/dashboard/clinics/:id/address" element={<ClinicAddressEdit />} /> */}
            <Route path="/dashboard/profile/clinic_address" element={<ClinicAddressEdit />} />

            <Route path="/dashboard/clinics/clinic_vacations" element={<ClinicVacations />} />
            <Route path="/dashboard/clinics/:id/clinic_vacations" element={<ClinicAdminVacations />} />


            <Route path="/dashboard/clinic/gallery" element={<ClinicGalleryEdit />} />
            <Route path="/dashboard/clinic/:id/gallery" element={<ClinicGalleryAdminEdit />} />

            <Route path="/dashboard/clinics/clinic_availability" element={<AvalibilityAdd />} />
            <Route path="/dashboard/clinics/:id/clinic_availability" element={<AvalibilityAdd />} />

            <Route path="/dashboard/doctors/list" element={<DoctorRegestrationList />} />
            <Route path="/dashboard/doctors/add" element={<DoctorRegestrationAdd />} />
            <Route path="/dashboard/profile" element={<DoctorRegestrationCliniEdit />} />
            <Route path="/dashboard/doctors/:id" element={<DoctorRegestrationCliniEdit />} />
            <Route path="/dashboard/users/:id/vacations" element={<DoctorVacationsClinics />} />
            <Route path="/dashboard/profile/vacations" element={<DoctorVacations />} />

            <Route path="/dashboard/clinics/:id/tariff" element={<ClientTarifList />} />
            <Route path="/dashboard/clinics/:id/assign-tariff" element={<AssignTariff />} />

            <Route path="/dashboard/patients/registration" element={<PatientRegistration />} />
            <Route path="/dashboard/patients/add" element={<PataientAdd />} />
            <Route path="/dashboard/patients/:uhid/info" element={<PatientDetails />} />
            <Route path="/dashboard/patients/:uhid/invoices" element={<PatientInvoices />} />
            <Route path="/dashboard/patients/:uhid/records" element={<PatientRecords />} />
            <Route path="/dashboard/patients/:uhid/appointments" element={<PatientAppointments />} />
            <Route path="/dashboard/patients/:uhid/visit_history" element={<PatientVisitHistory />} />

            {/* OpotoAdmin */}
            <Route path="/dashboard/orders" element={<Orders />} />
            <Route path="/dashboard/orders/:order_id/modify" element={<ModifyOrder />} />
            <Route path="/dashboard/store_management" element={<StoreManagement />} />
            <Route path="/dashboard/:storeId/store_management" element={<StoreManagement />} />
            <Route path="/dashboard/admin/profile" element={<AdminProfile />} />
            <Route path="/dashboard/masters/shipping" element={<ShippingMethods />} />
            <Route path="/dashboard/masters/delivery-agent" element={<DeliveryAgents />} />
            <Route path="/dashboard/masters/react-url" element={<ReactUrls />} />
            <Route path="/dashboard/add_store" element={<AddStore />} />
            <Route path="/dashboard/masters/manageproducts" element={<ManageProducts />} />
            <Route path="/dashboard/masters/inventory" element={<Inventory />} />
            <Route path="/dashboard/masters/categories" element={<Categories />} />
            <Route path="/dashboard/masters/subcategories" element={<Subcategories />} />
            <Route path="/dashboard/masters/products" element={<Products />} />
            <Route path="/dashboard/masters/banners" element={<Banner />} />
            <Route path="/dashboard/masters/coupons" element={<Coupon />} />

            <Route path="/dashboard/masters/brand" element={<Brand />} />
            <Route path="/dashboard/masters/products/add" element={<ProductForm />} />
            <Route path="/dashboard/masters/products/:id/edit" element={<ProductForm />} />

            <Route path="/dashboard/masters/blogposts" element={<Blogposts />} />
            <Route path="/dashboard/masters/referral_discount" element={<ReferralDiscount />} />
            <Route path="/dashboard/masters/loyalty_reward" element={<LoyaltyReward />} />
            <Route path="/dashboard/masters/free_item" element={<FreeItem />} />
            <Route path="/dashboard/masters/first_time" element={<FirstTime />} />
            <Route path="/dashboard/masters/bogo_discount" element={<BogosDiscount />} />
            <Route path="/dashboard/masters/discounts" element={<Discounts />} />
            <Route path="/dashboard/masters/jobposts" element={<Jobposts />} />
            <Route path="/dashboard/masters/contacts" element={<Contacts />} />
            <Route path="/dashboard/masters/blogcategories" element={<BlogCategory />} />
            <Route path="/dashboard/masters/jobcategories" element={<JobCategory />} />
            <Route path="/dashboard/masters/miscellaneoupages" element={<MiscellaneousPages />} />
            <Route path="/dashboard/masters/customers" element={<CustomerManagement />} />

            <Route path="/dashboard/masters/homedesign" element={<Homedesign />} />
            <Route path="/dashboard/masters/homedesign" element={<Homedesign />} />

            <Route path="/dashboard/masters/textblocks" element={<TextBlock />} />
            <Route path="/dashboard/masters/fullbanner" element={<FullWidthBanners />} />
            <Route path="/dashboard/masters/carousels" element={<Carousels />} />
            <Route path="/dashboard/masters/sitesettings" element={<Sitesetting />} />
            <Route path="/dashboard/masters/taxsettings" element={<TaxClassification />} />
            <Route path="/dashboard/masters/emailgateway" element={<EmailGateway />} />
            <Route path="/dashboard/masters/policies" element={<Policies />} />
            <Route path="/dashboard/masters/configure_charges_hyperlocal" element={<ConfigureChargesHyperlocal />} />
            <Route path="/dashboard/masters/configure_charges_conventional" element={<ConfigureChargesConventional />} />
            <Route path="/dashboard/masters/paymentgateway" element={<PaymentGateway />} />

            <Route path="/dashboard/cms/old-banner" element={<BannerOld />} />
            <Route path="/dashboard/cms/display-categorie" element={<DisplayCategorie />} />
            <Route path="/dashboard/cms/shop-deals" element={<ShopDeals />} />
            <Route path="/dashboard/cms/about-us" element={<AboutUs />} />
            <Route path="/dashboard/masters/news" element={<News />} />
            {/* <Route path="/dashboard/clients/:id/assign-tariffedit" element={<ClientTariffEdit/>}/> */}
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
